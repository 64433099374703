import { Injectable } from '@angular/core';
import { UsersService as UsersServiceApi, UserVm } from 'app/shared/api';
import { map, mapTo, Observable, of } from 'rxjs';
import { UserMapper } from '../mappers/user.mapper';
import { AuthRepository } from '../state/auth.repository';
import { UserV2Service } from 'app/shared/api-overrides/apiV2';
import { UserV2Mapper } from 'app/core/mappers/user-v2.mapper';
import UserV2Vm from 'app/features/user/models/user-v2-vm';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private usersServiceApi: UsersServiceApi,
    private userV2ServiceApi: UserV2Service,
    private userMapper: UserMapper,
    private userV2Mapper: UserV2Mapper,
    private authRepository: AuthRepository
  ) {}

  retrieveCurrentUser(): Observable<void> {
    if (this.authRepository.getCurrentUserSnapshot()) {
      return of(void 0);
    }

    return this.userV2ServiceApi.getUser().pipe(
      map((user: UserV2Vm) => {
        const currentUser = this.userV2Mapper.toModel(user);
        this.authRepository.updateUserV2(currentUser);
      }),
      mapTo<void>(void 0)
    );
  }
}
