import { Injectable } from '@angular/core';
import UserV2Vm from 'app/features/user/models/user-v2-vm';
import UserV2 from 'app/features/user/models/user-v2';

@Injectable({
  providedIn: 'root',
})
export class UserV2Mapper {
  toModel(userApi: UserV2Vm): UserV2 {
    if (!userApi) {
      return undefined;
    }

    const { id, firstName, lastName, email, createdDate, roles, organisation, projectsId } = userApi;

    return {
      id,
      firstName,
      lastName,
      email,
      roles,
      createdDate,
      organisation,
      projectsId,
    };
  }
}
