import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParameterCodec } from '@angular/common/http';
import { Configuration } from 'app/shared/api';
import { API_V2_PATH } from 'app/shared/api-overrides/apiV2/variables';
import { CustomHttpParameterCodec } from 'app/shared/api/encoder';
import { Observable } from 'rxjs';
import UserV2 from 'app/features/user/models/user-v2';
import { ROUTES } from 'app/core/routes.constants';

@Injectable({
  providedIn: 'root',
})
export class UserV2Service {
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(API_V2_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    this.configuration = configuration || new Configuration();
    if (typeof this.configuration.basePath !== 'string') {
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  public getUser(): Observable<UserV2> {
    return this.httpClient.get<UserV2>(`${this.configuration.basePath}/${ROUTES.me}`, {
      responseType: 'json',
      withCredentials: this.configuration.withCredentials,
      observe: 'body',
    });
  }
}
