import { Inject, Injectable, InjectionToken } from '@angular/core';
import { createStore, select, Store, withProps } from '@ngneat/elf';
import { OrganisationVm, UserVm } from 'app/shared/api';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { RightName } from '../rights/right-name.model';
import { Right } from '../rights/right.model';
import { RIGHTS } from '../rights/rights.constants';
import { ViewRightName } from '../rights/view-right-name.model';
import { ViewRight } from '../rights/view-right.model';
import { VIEW_RIGHTS } from '../rights/view-rights.constants';
import UserV2 from 'app/features/user/models/user-v2';

interface AuthProps {
  user: User | null;
}

const authStoreName = 'auth';

export type AuthStore = Store<{ name: string; state: AuthProps; config: undefined }, AuthProps>;
export const AuthStoreToken = new InjectionToken<AuthStore>('AuthStoreType', {
  providedIn: 'root',
  factory: (): AuthStore => createStore({ name: authStoreName }, withProps<AuthProps>({ user: null })),
});

@Injectable({
  providedIn: 'root',
})
export class AuthRepository {
  constructor(@Inject(AuthStoreToken) public authStore: AuthStore) {}
  getCurrentUserSnapshot(): User {
    return this.authStore.state.user;
  }

  getCurrentUserOrganisationSnapshot(): OrganisationVm {
    return this.authStore.state.user?.organisation;
  }

  getCurrentUser(): Observable<User> {
    return this.authStore.pipe(select((state) => state.user));
  }

  getCurrentUserOrganisationId(): Observable<string> {
    return this.authStore.pipe(select((state) => state.user?.organisation?.id));
  }

  getCurrentUserOrganisation(): Observable<OrganisationVm> {
    return this.authStore.pipe(select((state) => state.user?.organisation));
  }

  updateUser(user: User): void {
    this.authStore.update((state) => ({
      ...state,
      user,
    }));
  }

  updateUserV2(user: UserV2): void {
    this.authStore.update(
      (state) =>
        ({
          ...state,
          user,
        } as unknown as { user: User })
    );
  }

  getUserRights(): RightName[] {
    return this.getRightNames(this.authStore.state.user.roles);
  }

  getUserViewRights(): ViewRightName[] {
    return this.getViewRightNames(this.authStore.state.user.roles);
  }

  private getRightNames(userRoles: UserVm.RolesEnum[]): RightName[] {
    return RIGHTS.filter((right: Right) =>
      right.authorizedRoles.some((role: UserVm.RolesEnum) => userRoles.includes(role))
    ).map((right: Right) => right.name);
  }

  private getViewRightNames(userRoles: UserVm.RolesEnum[]): ViewRightName[] {
    return VIEW_RIGHTS.filter((right: ViewRight) =>
      right.authorizedRoles.some((role: UserVm.RolesEnum) => userRoles.includes(role))
    ).map((right: ViewRight) => right.name);
  }
}
